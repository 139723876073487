import { useState } from 'react';
import { InView } from 'react-intersection-observer';

type OnceVisibleStayVisibleProps = {
  children: (props: { hasBeenVisible: boolean }) => JSX.Element;
  onChange?: (x: boolean) => void;
  partialVisibility?: boolean;
  offset?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
};

const OnceVisibleStayVisible = ({
  children,
  onChange = () => {},
  partialVisibility = false,
  offset = null,
}: OnceVisibleStayVisibleProps) => {
  const [hasBeenVisible, setHasBeenVisible] = useState(false);

  const doOnChange = (inView: boolean) => {
    onChange(inView);

    if (!hasBeenVisible) {
      setHasBeenVisible(inView);
    }
  };

  const rootMargin = `${offset?.top || 0}px ${offset?.right || 0}px ${
    offset?.bottom || 0
  }px ${offset?.left || 0}px `;

  return (
    <InView
      as="div"
      onChange={doOnChange}
      rootMargin={rootMargin}
      threshold={partialVisibility ? 0 : 1}
      skip={hasBeenVisible}
    >
      {children({
        hasBeenVisible,
      })}
    </InView>
  );
};

export default OnceVisibleStayVisible;
